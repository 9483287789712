import types from '../types';

const initialState = {
  id: '',
  hide_my_agenda: false,
  hide_speakers: false,
  hide_notifications: false,
  hide_sponsors: false,
  show_session_seats: false,
  my_agenda_name: '',
  agenda_name: '',
  speakers_name: '',
  sponsors_name: '',
  widgets_order: [],
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SETTINGS_SUCCESS:
    case types.CREATE_SETTINGS_SUCCESS:
    case types.UPDATE_SETTINGS_SUCCESS:
      if (!action.payload) {
        return {
          ...state,
        };
      }

      const {
        hide_my_agenda,
        hide_speakers,
        hide_notifications,
        hide_sponsors,
        show_session_seats,
        my_agenda_name,
        agenda_name,
        speakers_name,
        sponsors_name,
        widgets_order,
        id
      } = action.payload;

      return {
        ...state,
        id,
        hide_my_agenda,
        hide_speakers,
        hide_notifications,
        hide_sponsors,
        show_session_seats,
        my_agenda_name,
        agenda_name,
        speakers_name,
        sponsors_name,
        widgets_order,
      };

    default:
      return state;
  }
};

export default settings;
