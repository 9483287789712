import styled from 'styled-components';

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.9em;

  & label[data-checked],
  label[data-checked]:hover {
    background-color: var(--blue-4);
  }
`;

const ToggleText = styled.p`
  margin: 0 8px 0 0;
`;

export { ToggleWrapper, ToggleText };
