import React, { useEffect, useState } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { compose } from 'lodash/fp';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  createNotification,
  deleteNotification,
  findAllNotifications,
  sendNotification,
  updateNotification
} from '../../../store/actions/notifications';
import { countAllSubscribers } from '../../../store/actions/subscribers';
import { getRemainingNotifications, selectNotifications } from '../../../store/selectors/notifications';
import { getSubscribersAllowed, getSubscribersCount } from '../../../store/selectors/subscribers';
import Page from '../../layout/page';
import ConfSelected from '../../../containers/conf-selected';
import Wireframe from './notifications-wireframe';
import NoNotifications from './no-notifications';
import PageWrapper from '../../layout/page-wrapper';
import { getConfLimits } from '../../../store/actions/upgrade';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import NotificationEditModal from './notification-edit-modal';
import Notification from './notification';
import { addFlag } from '../../../store/actions/flags';
import { getTimeFormat } from '../../../store/selectors/account';
import { endOfDayIsPast } from '../../utils/dates';
import { getUtcFromZonedTime } from '../../../services/DatesService';

const Wrapper = styled(PageWrapper)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  
  @media (min-width: 1220px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

// const Message = styled(NavLink)`
//   display: block;
//   margin-top: 20px;
//   text-align: center;
//   color: var(--red-2);
//   text-decoration: underline;
// `;

const WireframeContainer = styled.div`
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  
  @media (min-width: 640px) {
    margin-right: 50px;
  }
`;

const initialState = {
  title: '',
  body: ''
};

const onError = () => {
  // do nothing, we already displayed the flag
};

const getNotificationMessage = (t, maxSubscribersCount, subscribersCount) => {
  if (maxSubscribersCount === -1) {
    return t('notifications:subscribers_count_unlimited', { count: subscribersCount });
  }

  const messagesNumberToSend = subscribersCount <= maxSubscribersCount ? subscribersCount : maxSubscribersCount;

  return t('notifications:subscribers_count', { count: subscribersCount, max: messagesNumberToSend });
};

const ConfNotifications = ({ selectedConf }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(initialState);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const isSelectedConfPast = endOfDayIsPast(selectedConf.to);

  useEffect(() => {
    Promise.all([
      dispatch(getConfLimits(selectedConf.id)),
      dispatch(findAllNotifications(selectedConf.id)),
      dispatch(countAllSubscribers(selectedConf.id))
    ]).catch(onError);
  }, [dispatch, selectedConf.id]);

  const {
    notifications,
    remainingNotifications,
    subscribersCount,
    maxSubscribersCount,
    timeFormat
  } = useMappedState(state => {
    return {
      notifications: selectNotifications(state),
      remainingNotifications: getRemainingNotifications(state),
      subscribersCount: getSubscribersCount(state),
      maxSubscribersCount: getSubscribersAllowed(state),
      timeFormat: getTimeFormat(state)
    };
  });

  const handleSaveNotification = (data) => {
    const isNew = !notification.id;

    const payload = {
      title: data.title.trim(),
      body: data.body.trim(),
      conf_id: selectedConf.id,
      date: getUtcFromZonedTime(data.date, selectedConf.timezone)
    };

    if (!isNew) {
      payload.id = notification.id;
    }

    dispatch(isNew ? createNotification(payload) : updateNotification(payload))
      .then(() => {
        setIsEditModalOpen(false);
        setNotification(initialState);
      })
      .catch(onError);
  };

  const handleSendNotification = (id) => {
    dispatch(sendNotification(id))
      .then(() => {
        return dispatch(
          addFlag({
            appearance: 'success',
            title: 'notifications:created',
            timeout: 5000
          })
        );
      })
      .catch(onError);
  };

  const addNotificationModal = (
    <>
      {isEditModalOpen && (
        <NotificationEditModal
          onClose={() => setIsEditModalOpen(false)}
          notification={notification}
          timeFormat={timeFormat}
          from={selectedConf.from}
          to={selectedConf.to}
          onSave={handleSaveNotification}
        />
      )}
      <div
        {...(isSelectedConfPast
          ? { 'data-tip': t('common:errors.past_event') }
          : {})
        }
      >
        <Button
          appearance="primary"
          className="create_link-mask"
          iconBefore={<AddIcon size="small" label="add icon" />}
          onClick={() => {
            setNotification(initialState);
            setIsEditModalOpen(true);
          }}
          isDisabled={remainingNotifications <= 0 || isSelectedConfPast}
        >
          {t('notifications:add')}
        </Button>
      </div>
    </>
  );

  return (
    <Wrapper>
      <WireframeContainer>
        <Wireframe title={t('notifications:example')} body={t('notifications:example_body')} />
      </WireframeContainer>

      <Page
        title={t('notifications:title')}
        titleTooltip={t('notifications:titleTooltip')}
        style={{ margin:'0 0 50px 0', flex: 1, minWidth: 288, width: '100%' }}
        showProgress={false}
        addChildren={addNotificationModal}
      >
        {remainingNotifications > 0 && (
          <div style={{ marginBottom: '20px' }}>
            {t('notifications:remaining', { count: remainingNotifications })}
          </div>
        )}
        <div>
          {getNotificationMessage(t, maxSubscribersCount, subscribersCount)}
        </div>
        {!!notifications.length && (
          <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            {notifications.map(notification => (
              <Notification
                key={notification.id}
                timeFormat={timeFormat}
                notification={notification}
                receiversCount={Math.min(subscribersCount, maxSubscribersCount)}
                isConfActivated={selectedConf.activated}
                onEdit={() => {
                  setNotification(notification);
                  setIsEditModalOpen(true);
                }}
                onSend={handleSendNotification}
                onDelete={() => {
                  setNotification(initialState);
                  dispatch(deleteNotification(notification.id));
                }}
              >
              </Notification>
            ))}
          </div>
        )}
        {remainingNotifications <= 0 && <NoNotifications selectedConfId={selectedConf.id} />}
        {/*TODO do we need a warning about not active event?*/}
        {/*{!selectedConf.activated && (*/}
        {/*  <Message to={`/conf/${selectedConf.id}`} exact>*/}
        {/*    {t('notifications:create:message')}*/}
        {/*  </Message>*/}
        {/*)}*/}
      </Page>
    </Wrapper>
  );
};

export default compose(
  withRouter,
  ConfSelected
)(ConfNotifications);
