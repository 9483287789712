import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useMappedState } from 'redux-react-hook';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog';
import { isEmpty } from 'lodash/fp';
import styled from 'styled-components';
import { areLinksLoading } from '../../../../store/selectors/links';
import { createLink, updateLink } from '../../../../store/actions/links';
import { isValidUrl } from '../../../../services/ValidationService';
import CloseModalButton from '../../../utils/modals/close-modal-button';
import LinkChoices from './link-choices';
import { LinkForm } from './link-form';

const FormContainer = styled.div`
  padding: 0 15px;
`;

const validateName = name => (name === '' ? { name: 'links:form.errors.empty_name' } : null);
const validateUrl = url => (!isValidUrl(url) ? { content: 'links:form.errors.bad_url' } : null);
const validateContent = (type, content) => (type === 'url' ? validateUrl(content) : null);
const validateOnSubmit = data => {
  return {
    ...validateName(data.name),
    ...validateContent(data.type, data.content)
  };
};

// eslint-disable-next-line
export default ({ isOpen, onClose, conf = {}, link = {}, apps, onSave }) => {
  const { t } = useTranslation();
  const [selectedLink, selectLink] = useState(link);
  const [errors, setErrors] = useState({});
  const isEditing = !isEmpty(link);
  const dispatch = useDispatch();
  const isUsedInProgram = isEmpty(conf);

  const { isLoading } = useMappedState(state => ({
    isLoading: areLinksLoading(state)
  }));

  useEffect(() => {
    selectLink(link);
  }, [link]);

  const closeModal = (data) => {
    // reset the creating/editing link to its initial state
    selectLink(link);
    setErrors({});
    onClose(data);
  };

  const onBack = () => {
    setErrors({})
    selectLink({ id: selectedLink.id })
  };

  const onSubmit = data => {
    const payload = {
      ...data,
      type: (data.type || {}).value || selectedLink.type,
      icon: data.icon || selectedLink.icon,
      app: selectedLink.app,
      conf_id: conf.id
    }

    const errors = validateOnSubmit(payload);
    setErrors(errors);

    if (!isEmpty(errors)) {
      return;
    }

    // for session links
    if (isUsedInProgram) {
      closeModal({ ...payload, id: selectedLink.id });
      return;
    }

    const action = isEditing
      ? updateLink({ ...payload, id: selectedLink.id })
      : createLink(payload);

    dispatch(action)
      .then(() => {
        closeModal();
        onSave();
      });
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          shouldScrollInViewport
          shouldCloseOnOverlayClick={false}
          onClose={closeModal}
          width={selectedLink && selectedLink.type === 'content' ? 'x-large' : 'medium'}
        >
          <ModalHeader>
            <ModalTitle>
              {t(`links:form.${isEditing ? 'edit' : 'create'}.title`) +
              (selectedLink.app ? ' - ' + selectedLink.name : '')}
            </ModalTitle>
            <CloseModalButton onClick={closeModal} />
          </ModalHeader>
          <ModalBody>
            {!selectedLink.name ? (
              <LinkChoices
                onChoiceSelected={linkChoice => {
                  selectLink({
                    ...selectedLink,
                    ...linkChoice
                  });
                }}
                apps={apps}
              />
            ) : (
              <FormContainer>
                <LinkForm
                  onSubmit={onSubmit}
                  onBack={onBack}
                  isLoading={isLoading}
                  isEditing={isEditing}
                  link={selectedLink}
                  errors={errors}
                  showDefaultAppToggle={!isUsedInProgram}
                  showHideAppToggle={!isUsedInProgram}
                  showHideWebToggle={!isUsedInProgram}
                />
              </FormContainer>
            )}
          </ModalBody>
        </Modal>
      )}
    </ModalTransition>
  );
};
