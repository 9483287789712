import React from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from '@atlaskit/toggle';
import { RadioGroup } from '@atlaskit/radio';
import { ToggleText, ToggleWrapper } from './toggle';

const WebSettings = ({
  isHidden,
  isShownInMenu,
  onHiddenChange,
  onShowInMenuChange
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ToggleWrapper>
        <ToggleText>{t('links:is_hidden_web')}</ToggleText>
        <Toggle
          size="large"
          isChecked={isHidden}
          onChange={onHiddenChange}
        />
      </ToggleWrapper>

      {!isHidden && (
        <div
          style={{
            display: 'flex',
            marginLeft: '15px',
            marginTop: '-5px',
            fontSize: '0.9em'
          }}
        >
          <RadioGroup
            defaultValue={isShownInMenu ? "true" : "false"}
            options={[
              {
                name: 'show_in_menu',
                value: "false",
                label: (
                  <p
                    style={{
                      margin: 0,
                      marginRight: '5px',
                      paddingTop: '1px',
                    }}
                  >
                    {t('links:show_on_page')}
                  </p>
                )
              },
              {
                name: 'show_in_menu',
                value: "true",
                label: (
                  <p
                    style={{
                      margin: 0,
                      paddingTop: '1px',
                    }}
                  >
                    {t('links:show_in_menu')}
                  </p>
                )
              },
            ]}
            onChange={(event) => onShowInMenuChange(event.currentTarget.value)}
          />
        </div>
      )}
    </>
  )
};

export { WebSettings }
