import React, { useEffect, useState } from 'react';
import { compose, merge } from 'lodash/fp';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { useTranslation } from 'react-i18next';

import PageWrapper from '../components/layout/page-wrapper';
import ConfService from '../services/ConfsService';
import { saveWizard, getWizard } from '../store/actions/wizard';
import { isWizardFetched, isWizardSaved } from '../store/selectors/wizard';
import ConfThemeForm from '../components/confs/theme/conf-theme-form';
import Card from '../components/layout/card';
import Wireframe from '../components/confs/theme/wireframe';
import Stepper from '../components/wizard/stepper';
import { LOCALES_MAP } from '../i18n/i18n';

const MainContainer = styled(PageWrapper)`
  max-width: 1100px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 960px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const WireframeWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 330px;

  @media (min-width: 960px) {
    margin-right: 50px;
  }
`;

const ThemeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const FormCard = styled(Card)`
  padding: 30px;
`;

const StepperWrapper = styled.div`
  width: 100%;
`;

const getConfFromUrlParams = (locationSearch) => {
  const params = new URLSearchParams(locationSearch);

  return {
    name: params.get('name'),
    from: params.get('from'),
    lang: params.get('lang'),
    gclid: params.get('gclid'),
  };
};

const Wizard = (props) => {
  const confFromParams = getConfFromUrlParams(props.location.search);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { wizardIsFetched, wizardIsSaved, savedWizard } = useMappedState((state) => ({
    wizardIsFetched: isWizardFetched(state),
    wizardIsSaved: isWizardSaved(state),
    // this wizard contains the default state value
    // or the one saved into localStorage (if there was one) once we dispatched the getWizard() event
    savedWizard: state.wizard.value,
  }));

  // this wizard is the one we are trying to create
  // so we first get what was previously saved (our redux state)
  // and we merge it with url parameters to get our first editing state
  const conf = {
    name: confFromParams.name,
    from: confFromParams.from,
    // as we are on the free plan it should be only one day
    to: confFromParams.from
  };

  const gclid = confFromParams.gclid;

  const [wizard, setWizard] = useState(merge(savedWizard, { conf, gclid }));

  const setWizardThemeItem = (item) => (value) => {
    setWizard(
      merge(wizard, {
        theme: { [item]: value },
      })
    );
  };

  useEffect(() => {
    if (!wizardIsFetched) {
      // lang is set here as it runs only once
      if (Object.keys(LOCALES_MAP).includes(confFromParams.lang)) {
        i18n.changeLanguage(confFromParams.lang);
      }

      dispatch(getWizard());
    }
    // if the wizard.conf is not complete, we can't create it,
    // so go back to the landing page
    if (wizardIsFetched && !ConfService.isCreatable(wizard.conf)) {
      return window.location.replace(process.env.REACT_APP_LANDING_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, wizardIsFetched, wizard.conf]);

  if (wizardIsSaved) {
    return <Redirect to="/" />;
  }

  return (
    <MainContainer>
      <WireframeWrapper>
        <Wireframe
          color={wizard.theme.color}
          logo={wizard.theme.logo}
          background={wizard.theme.background}
          style={{ marginRight: 0 }}
        />
      </WireframeWrapper>
      <ThemeWrapper>
        <h1 style={{ textAlign: 'center', marginTop: '5px' }}>
          {t('wizard:encouragement')}&nbsp;
          <span role="img" aria-label="muscle">
            💪
          </span>
        </h1>
        <StepperWrapper>
          <Stepper />
        </StepperWrapper>
        <FormCard>
          <ConfThemeForm
            base64={true}
            logo={wizard.theme.logo}
            background={wizard.theme.background}
            color={wizard.theme.color}
            font={wizard.theme.font}
            helperText={t('wizard:helper')}
            submitText={t('wizard:submit')}
            onSetLogo={setWizardThemeItem('logo')}
            onSetBackground={setWizardThemeItem('background')}
            onSetColor={setWizardThemeItem('color')}
            onSetFont={setWizardThemeItem('font')}
            onSave={() => dispatch(saveWizard(wizard))}
          />
        </FormCard>
      </ThemeWrapper>
    </MainContainer>
  );
};

export default compose(withRouter)(Wizard);
