import axios from 'axios';
import { TOKEN_KEY, SUPERADMIN_KEY, ProductsTypes } from '../constants';
import { getItem, removeItem } from './StorageService';

export default class HttpService {
  constructor(baseURL = process.env.REACT_APP_API_URL) {
    this.defaultHeaders = {
      Prefer: 'return=representation',
      Accept: 'application/vnd.pgrst.object+json'
    };

    this.http = axios.create({
      baseURL,
      headers: {}
    });

    this.http.interceptors.request.use(
      config => {
        const token = getItem(TOKEN_KEY) || '';

        config.headers['Authorization'] = `Bearer ${token}`;

        try {
          const superAdmin = getItem(SUPERADMIN_KEY);

          if (superAdmin && baseURL !== process.env.REACT_APP_SERVICES_URL) {
            config.headers['SuperAdminToken'] = superAdmin.userId;

            if (config.params && config.params.owner_id) {
              config.params.owner_id = `eq.${superAdmin.userId}`;
            }
          }
        } catch (e) {
          console.error(e);
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    this.http.interceptors.response.use(
      response => response,
      error => {
        if (!error.response || error.response.status >= 500) {
          const message = error.code === 'ERR_NETWORK'
            ? 'common:errors:too_long_error'
            : 'common:errors:server_error';

          return Promise.reject(message);
        }

        switch (error.response.status) {
          case 401:
            removeItem(TOKEN_KEY);
            window.location = '/login';
            break;
          case 402:
            return Promise.reject({ type: ProductsTypes.PLAN, entity: this.entity, ...error.response.data });
          default:
            return Promise.reject(error.response);
        }
      }
    );
  }

  onResponse(res = {}) {
    return res ? res.data : Promise.reject('common:errors:server_error');
  }

  onError(error) {
    return Promise.reject(error);
  }
}
