import React from 'react';
import styled from 'styled-components';
import { DefaultBackground } from './default-background';

const Container = styled.div`
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  position: relative;
  flex: 1;

  @media (min-width: 864px) {
    margin-right: 50px;
  }
`;

const BackgroundContainer = styled.div`
  background-color: #ebebeb;
  border-radius: 55px;
  padding: 15px;
`;

const BackgroundR = styled.div`
  background-color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-clip: content-box;
  border-radius: 45px;
  overflow: hidden;
`;

const BackgroundBlur = styled.div`
  backdrop-filter: blur(6px);
`;
const BackgroundF = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

// eslint-disable-next-line
export default ({ color, logo, background, style, children }) => {
  const svg = (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1237 2548"
      style={{ display: 'block', margin: '-15px' }}
    >
      <defs>
        <style>
          {`.cls-1,.cls-32{fill:none;}.cls-33{fill:#ebebeb;}.cls-4{clip-path:url(#clip-path);}.cls-5{fill:${
            color || '#b7b7b7'
          };}.cls-6{fill:#f6f7f8;}.cls-11,.cls-7{fill:${
            color || '#b6b6b7'
          };}.cls-12,.cls-7,.cls-8{opacity:0.1;}.cls-12,.cls-15,.cls-18,.cls-7{isolation:isolate;}.cls-9{fill:#f6f6fa;}.cls-10,.cls-15{opacity:0.2;}.cls-13{fill:#ffd038;}.cls-14{fill:#a8a7a7;}.cls-16{fill:#d28784;}.cls-17{fill:#4d4a80;}.cls-18{opacity:0.05;}.cls-19{fill:#cfdbf0;}.cls-20{fill:#cb8093;}.cls-21{fill:#1d1536;}.cls-22{fill:#fbd0c6;}.cls-23{fill:#626695;}.cls-24{fill:#f8ccdf;}.cls-25{fill:#985c7e;}.cls-26{fill:url(#Dégradé_sans_nom);}.cls-27{fill:#da898a;}.cls-28{fill:#2e3038;}.cls-29{fill:#484563;}.cls-30{fill:#f06680;}.cls-31{fill:#472727;}.cls-32{stroke:#565a86;stroke-miterlimit:10;stroke-width:0.87px;}.cls-33{fill-rule:evenodd;}`}
        </style>
        {/*TODO this commented block causes an wrong behaviour of the tooltips mask on theme page*/}
        {/*<clipPath id="clip-path" transform="translate(0 0)">*/}
        {/*  <rect className="cls-1" x="56" y="56" width="1125" height="2436" rx="120" />*/}
        {/*</clipPath>*/}
        <linearGradient
          id="Dégradé_sans_nom"
          x1="3636.15"
          y1="446.78"
          x2="3636.15"
          y2="202.27"
          gradientTransform="matrix(-1, 0, 0, 1, 3988.94, 1632.63)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
      </defs>
      <title>conf.app Preview</title>
      <g id="Page-1">
        <g id="iPhoneX--white-">
          <g id="white">
            <g id="mask">
              <g className="cls-4">
                <path
                  className="cls-5"
                  d="M106.59,232.56h67.87a2.61,2.61,0,0,0,2.61-2.61h0v-6.53a2.61,2.61,0,0,0-2.61-2.61H106.59a2.61,2.61,0,0,0-2.61,2.61h0V230A2.61,2.61,0,0,0,106.59,232.56Zm0,26.1h67.87a2.61,2.61,0,0,0,2.61-2.61v-6.52a2.61,2.61,0,0,0-2.61-2.61H106.59a2.61,2.61,0,0,0-2.61,2.61h0v6.52A2.61,2.61,0,0,0,106.59,258.66Zm0,26.1h67.87a2.61,2.61,0,0,0,2.61-2.61v-6.52a2.61,2.61,0,0,0-2.61-2.61H106.59a2.61,2.61,0,0,0-2.61,2.61v6.52A2.61,2.61,0,0,0,106.59,284.76Z"
                  transform="translate(0 0)"
                />
                {!logo && <circle className="cls-5" cx="381.13" cy="251.79" r="37.31" />}
                <path
                  className="cls-6"
                  d="M113.93,597.61H392.21c3.69,0,6.69,2.3,6.69,5.14V795.29c0,2.84-3,5.14-6.69,5.14H113.93c-3.69,0-6.69-2.3-6.69-5.14V602.75C107.24,599.91,110.24,597.61,113.93,597.61Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-5"
                  d="M123.08,831.36h260c8.74,0,15.83,5.14,15.83,11.48v5.48c0,6.35-7.09,11.49-15.83,11.49h-260c-8.74,0-15.83-5.14-15.83-11.49v-5.48C107.25,836.5,114.34,831.36,123.08,831.36Z"
                  transform="translate(0 0)"
                />
                <rect
                  className="cls-5"
                  x="203.8"
                  y="653.45"
                  width="91.14"
                  height="91.14"
                  rx="25.15"
                />
                <path
                  className="cls-6"
                  d="M480.89,597.61H759.17c3.69,0,6.69,2.3,6.69,5.14V795.29c0,2.84-3,5.14-6.69,5.14H480.89c-3.69,0-6.69-2.3-6.69-5.14V602.75C474.2,599.91,477.2,597.61,480.89,597.61Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-5"
                  d="M490,831.36H750c8.74,0,15.83,5.14,15.83,11.48v5.48c0,6.35-7.09,11.49-15.83,11.49H490c-8.74,0-15.83-5.14-15.83-11.49v-5.48C474.21,836.5,481.3,831.36,490,831.36Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-5"
                  d="M478.78,236.33H761.29c9.5,0,17.2,5.59,17.2,12.48v6c0,6.89-7.7,12.48-17.2,12.48H478.78c-9.5,0-17.2-5.59-17.2-12.48v-6C461.58,241.92,469.28,236.33,478.78,236.33Z"
                  transform="translate(0 0)"
                />
                <rect
                  className="cls-5"
                  x="570.76"
                  y="653.45"
                  width="91.14"
                  height="91.14"
                  rx="25.15"
                />
                {!background && <DefaultBackground />}
                <path
                  className="cls-6"
                  d="M113.93,924.4H392.21c3.69,0,6.69,2.3,6.69,5.14v192.54c0,2.84-3,5.14-6.69,5.14H113.93c-3.69,0-6.69-2.3-6.69-5.14V929.54C107.24,926.7,110.24,924.4,113.93,924.4Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-5"
                  d="M123.08,1158.15h260c8.74,0,15.83,5.14,15.83,11.48v5.48c0,6.35-7.09,11.49-15.83,11.49h-260c-8.74,0-15.83-5.14-15.83-11.49v-5.48C107.25,1163.29,114.34,1158.15,123.08,1158.15Z"
                  transform="translate(0 0)"
                />
                <rect
                  className="cls-5"
                  x="203.8"
                  y="980.24"
                  width="91.14"
                  height="91.14"
                  rx="25.15"
                />
                <path
                  className="cls-6"
                  d="M480.89,924.4H759.17c3.69,0,6.69,2.3,6.69,5.14v192.54c0,2.84-3,5.14-6.69,5.14H480.89c-3.69,0-6.69-2.3-6.69-5.14V929.54C474.2,926.7,477.2,924.4,480.89,924.4Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-5"
                  d="M490,1158.15H750c8.74,0,15.83,5.14,15.83,11.48v5.48c0,6.35-7.09,11.49-15.83,11.49H490c-8.74,0-15.83-5.14-15.83-11.49v-5.48C474.21,1163.29,481.3,1158.15,490,1158.15Z"
                  transform="translate(0 0)"
                />
                <rect
                  className="cls-5"
                  x="570.76"
                  y="980.24"
                  width="91.14"
                  height="91.14"
                  rx="25.15"
                />
                <path
                  className="cls-6"
                  d="M847.86,597.61h278.28c3.69,0,6.69,2.3,6.69,5.14V795.29c0,2.84-3,5.14-6.69,5.14H847.86c-3.69,0-6.69-2.3-6.69-5.14V602.75C841.17,599.91,844.17,597.61,847.86,597.61Z"
                  transform="translate(0 0)"
                />
                <path
                  className="cls-5"
                  d="M857,831.36h260c8.74,0,15.83,5.14,15.83,11.48v5.48c0,6.35-7.09,11.49-15.83,11.49H857c-8.74,0-15.83-5.14-15.83-11.49v-5.48C841.16,836.5,848.25,831.36,857,831.36Z"
                  transform="translate(0 0)"
                />
                <rect
                  className="cls-5"
                  x="937.72"
                  y="653.45"
                  width="91.14"
                  height="91.14"
                  rx="25.15"
                />
              </g>
            </g>
            <path
              id="bar"
              className="cls-33"
              d="M316,54H922V74a68,68,0,0,1-68,68H384a68,68,0,0,1-68-68Z"
              transform="translate(0 0)"
            />
          </g>
        </g>
      </g>
    </svg>
  );

  return (
    <Container style={style}>
      <BackgroundContainer>
        <BackgroundR
          style={{
            backgroundImage: background ? `url("${background}")` : 'none',
          }}
        >
          <BackgroundBlur>
            <BackgroundF
              style={{
                backgroundImage: background ? `url("${background}")` : 'none',
              }}
            >
              {logo && (
                <img
                  style={{
                    top: '55px',
                    position: 'absolute',
                    left: '50px',
                    height: '30px',
                    width: '30px',
                    objectFit: 'cover',
                    border: '1px solid #ebebeb',
                    borderRadius: '50%',
                  }}
                  alt="Event logo"
                  src={logo}
                />
              )}
              {children}
              {svg}
            </BackgroundF>
          </BackgroundBlur>
        </BackgroundR>
      </BackgroundContainer>
    </Container>
  );
};
