import types from '../types';
import LinksService from '../../services/LinksService';
import handleError from '../utils/errors';
import { updateEntityProgress } from './progress';

export const createLink = link => dispatch => {
  dispatch({ type: types.CREATE_LINK_REQUEST });
  return LinksService.create(link)
    .then(createdLink => {
      dispatch(updateEntityProgress('links', true));
      return dispatch({
        type: types.CREATE_LINK_SUCCESS,
        payload: createdLink
      });
    })
    .catch(err => handleError(types.CREATE_LINK_ERROR, err, dispatch));
};

export const updateLink = link => dispatch => {
  dispatch({ type: types.UPDATE_LINK_REQUEST });
  return LinksService.update(link)
    .then(updatedLink => {
      dispatch(updateEntityProgress('links', true));
      return dispatch({
        type: types.UPDATE_LINK_SUCCESS,
        payload: updatedLink
      });
    })
    .catch(err => handleError(types.UPDATE_LINK_ERROR, err, dispatch));
};

export const deleteLink = (linkId, remainingLinks) => dispatch => {
  return LinksService.delete(linkId)
    .then(() =>
      dispatch({
        type: types.DELETE_LINK,
        payload: linkId
      })
    )
    .then(() => {
      return dispatch(updateEntityProgress('links', remainingLinks - 1 > 0));
    });
};

export const findLinks = confId => dispatch => {
  dispatch({ type: types.FIND_ALL_LINKS_REQUEST });

  return LinksService.findAllOfConf(confId)
    .then(confs => {
      return dispatch({
        type: types.FIND_ALL_LINKS_SUCCESS,
        payload: confs
      });
    })
    .catch(err => handleError(types.FIND_ALL_LINKS_ERROR, err, dispatch));
};
